import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import PhotoGallery from "../../components/photo-gallery/photo-gallery.js"

const Images = () => {
    const data = useStaticQuery(graphql`
        query CloudinaryImagesGalleryNewZealand {
            allCloudinaryMedia(
                filter: {
                    public_id: { regex: "/^rm-photos?/albums?/new-zealand?//" }
                }
                sort: { created_at: ASC }
            ) {
                edges {
                    node {
                        public_id
                        secure_url
                        context {
                            custom {
                                alt
                                caption
                            }
                        }
                    }
                }
            }
        }
    `)

    return <PhotoGallery data={data} />
}

export default function Gallery() {
    return <Layout>{Images()}</Layout>
}
